import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 获取已核销的电子卡券清单接口
 * @param {Object} data
 * @returns
 */
export function pageVerifiedTicketBill (data) {
  return request.post('/ooh-life/v1/verifyBill/pageVerifiedTicketBill', qs.stringify(data))
}

/**
 * 获取已核销电子卡券的总金额和数量
 * @param {Object} data
 * @returns
 */
export function countVerifiedTicketAmount (data) {
  return request.post('/ooh-life/v1/verifyBill/countVerifiedTicketAmount', qs.stringify(data))
}

/**
 * 根据公司id获取地推销售列表
 * @param {Object} data
 * @returns
 */
export function getUserListByCompany (data) {
  return request.post('/ooh-life/v1/verifyBill/getUserListByCompany', qs.stringify(data))
}
