<template>
  <div>
    <Row :gutter="8" class="m-b-10">
      <i-col span="3" v-if="role === 1">
        <Select v-model="query.companyId" clearable filterable size="small" placeholder="选择地推公司，可搜索">
          <Option v-for="item in companyArray" :key="'cid_' + item.id" :value="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="3" v-if="role === 2">
        <Select v-model="query.userId" clearable filterable size="small" placeholder="选择销售，可搜索">
          <Option v-for="item in salerArray" :key="'uid_' + item.id" :value="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <DatePicker placeholder="选择核销日期范围" size="small" v-model="dateRage" type="daterange" split-panels
          style="width:100%" :editable="false" clearable @on-change="onChangeDate"></DatePicker>
      </i-col>
      <i-col span="5">
        <i-input v-model="query.keyWords" size="small" placeholder="关键字（公司名称，商品名称，商家名称，卡券码号，订单号）"></i-input>
      </i-col>
      <i-col span="5">
        <i-button size="small" class="m-r-5" type="primary" icon="ios-search" @click="handleSearch">搜索</i-button>
        <i-button size="small" class="m-r-5" type="success" icon="ios-search" @click="onExport">导出Excel</i-button>
      </i-col>
    </Row>
    <Row :gutter="8" class="m-b-10">
      <i-col span="12" class="text-center">
        <Card>
          <div class="p-b-10 p-t-10">
            <h2>核销总数量</h2>
          </div>
          <div class="text-orange">
            <h1>{{ formartNumber(quantity) }}</h1>
          </div>
        </Card>

      </i-col>
      <i-col span="12" class="text-center">
        <Card>
          <div class="p-b-10 p-t-10">
            <h2>核销总金额</h2>
          </div>
          <div class="money">
            <h1>{{ formartMoney(amount) }}</h1>
          </div>
        </Card>

      </i-col>
    </Row>
    <Table size="small" stripe :data="list" :columns="tableColumns" :loading="tableLoading"></Table>
    <div class="paging_style">
      <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator :current="query.pageNumber"
        @on-change="handlePageChange"></Page>
    </div>
  </div>
</template>

<script>
import { downloadFileRequest } from '@/utils/download'
import { toMoney, toNumber } from '@/utils/wnumb_own'
import { getCompanyList } from '@/api/os/company'
import { pageVerifiedTicketBill, countVerifiedTicketAmount, getUserListByCompany } from '@/api/life/verifyBill'
export default {
  props: {
    role: {
      type: Number, // 1:业主，2：地推公司
      default: 1
    }
  },
  data () {
    return {
      companyArray: [],
      salerArray: [],
      dateRage: [],
      amount: 0, // 核销总金额
      quantity: 0, // 核销总数量
      query: {
        pageNumber: 1,
        pageSize: 15,
        publisherId: undefined,
        companyId: undefined,
        keyWords: '',
        userId: undefined,
        verifyStartDate: '',
        verifyEndDate: '',
        businessId: undefined
      },
      list: [],
      total: 0,
      tableLoading: false,
      tableColumns: [],
      tableShowColumns: new Map([
        [1, ['orderNo', 'orderCreateTime', 'itemCode', 'itemName', 'deadline', 'businessName', 'buyerPhone', 'companyName', 'discountPrice', 'realPayment', 'verifySourceName', 'userName', 'verifyTime']], // 业主
        [2, ['orderNo', 'orderCreateTime', 'itemCode', 'itemName', 'deadline', 'businessName', 'buyerPhone', 'contractUserName', 'discountPrice', 'realPayment', 'verifySourceName', 'userName', 'verifyTime']]
      ])
    }
  },
  created () {
    this.query.publisherId = this.$store.getters.token.userInfo.publisherId
    this.tableColumns = this.getTableColumns()
    if (this.role === 1) {
      this.getCompanyData()
    } else {
      this.query.companyId = this.$store.getters.token.userInfo.companyId
      this.getSalerData()
    }
    this.getStatData()
    this.getTableData()
  },
  methods: {
    /**
     * 动态生成Table列表
     */
    getTableColumns () {
      const tableColumnArray = {
        orderNo: { title: '订单编号', key: 'orderNo', align: 'center' },
        itemCode: { title: '商品编码', key: 'itemCode', align: 'center' },
        itemName: { title: '商品名称', key: 'itemName', align: 'center' },
        businessName: { title: '商家名称', key: 'businessName', align: 'center' },
        buyerPhone: { title: '买家电话', key: 'buyerPhone', align: 'center' },
        companyName: { title: '地推公司', key: 'companyName', align: 'center' },
        contractUserName: { title: '销售', key: 'contractUserName', align: 'center' },
        orderCreateTime: { title: '创建时间', key: 'orderCreateTime', align: 'center' },
        discountPrice: {
          title: '商品单价',
          key: 'discountPrice',
          align: 'center',
          render (h, { row }) {
            return h('span', {
              style: { color: '#44bd32', 'font-weight': 'bold' }
            }, toMoney(row.discountPrice))
          }
        },
        realPayment: {
          title: '实际金额',
          key: 'realPayment',
          align: 'center',
          render (h, { row }) {
            return h('span', {
              style: { color: '#44bd32', 'font-weight': 'bold' }
            }, toMoney(row.realPayment))
          }
        },
        userName: { title: '核销人员', key: 'userName', align: 'center' },
        verifySourceName: { title: '核销来源', key: 'verifySourceName', align: 'center' },
        verifyTime: { title: '核销时间', key: 'verifyTime', align: 'center' },
        deadline: {
          title: '卡券有效期',
          align: 'center',
          render (h, { row }) {
            return h('span', row.effectiveStartTime !== '' ? `${row.effectiveStartTime}至${row.effectiveEndTime}` : '/')
          }
        }
      }

      const data = []
      var showColumns = this.tableShowColumns.get(this.role)
      if (!showColumns || showColumns.length === 0) {
        showColumns = this.taskTypeShowColumns.get(1)
      }
      showColumns.forEach(col => data.push(tableColumnArray[col]))

      return data
    },
    getTableData () {
      this.tableLoading = true
      pageVerifiedTicketBill(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
        }
      }).finally(() => {
        this.tableLoading = false
      })
    },
    onChangeDate (date) {
      this.query.verifyStartDate = date[0]
      this.query.verifyEndDate = date[1]
    },
    /**
     * 获取业主下属公司
     */
    getCompanyData () {
      this.companyArray = []
      getCompanyList({ companyId: this.query.publisherId, type: 4, enabled: true }).then(res => {
        if (res && !res.errcode) {
          this.companyArray = res
        }
      })
    },
    /**
     * 获取销售人员数据
     */
    getSalerData () {
      this.salerArray = []
      const postData = {
        publisherId: this.query.publisherId,
        companyId: this.query.companyId

      }
      getUserListByCompany(postData).then(res => {
        if (res && !res.errcode) {
          this.salerArray = res
        }
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.getStatData()
      this.getTableData()
    },
    handlePageChange (curPage) {
      this.query.pageNumber = curPage
      this.getTableData()
    },
    /**
     * 获取统计数据
     */
    getStatData () {
      this.amount = 0
      this.quantity = 0
      const postData = {
        publisherId: this.query.publisherId,
        companyId: this.query.companyId,
        userId: this.query.userId,
        verifyStartDate: this.query.verifyStartDate,
        verifyEndDate: this.query.verifyEndDate,
        keyWords: this.query.keyWords
      }
      countVerifiedTicketAmount(postData).then(res => {
        if (res && !res.errcode) {
          this.amount = res.amount
          this.quantity = res.quantity
        }
      })
    },
    formartMoney (value) {
      return toMoney(value)
    },
    formartNumber (value) {
      return toNumber(value)
    },
    onExport () {
      const postData = {
        publisherId: this.query.publisherId,
        companyId: this.query.companyId,
        userId: this.query.userId,
        verifyStartDate: this.query.verifyStartDate,
        verifyEndDate: this.query.verifyEndDate,
        keyWords: this.query.keyWords
      }
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-life/v1/verifyBill/exportVerifiedTicketBill', postData, '电子卡券核销清单')
    }
  }
}
</script>
